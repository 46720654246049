//
// background.scss
// Extended from Bootstrap
//

.bg-cover {
  background: no-repeat center center / cover;
}

.hidden-bg {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  max-height: 10%;
  min-height: 260px;
  background-color: var(--#{$prefix}white);
}
