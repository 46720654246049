// Extended from bootstrap
// Headings
//

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    letter-spacing: -0.03rem;
}
