// button
// Primary Link

.btn-primary-link {
    font-weight: $font-weight-bold;
    color: var(--#{$prefix}primary);
    text-decoration: none;
    border-bottom: 2px solid var(--#{$prefix}primary);
    &:hover {
        color: #ff3912;
        text-decoration: none;
        border-bottom: 2px solid #ff3912;
    }
    &:focus,
    &.focus {
        text-decoration: none;
        box-shadow: none;
    }
    &:disabled,
    &.disabled {
        color: $btn-link-disabled-color;
        pointer-events: none;
    }
}

// Secondary link

.btn-secondary-link {
    font-weight: $font-weight-bold;
    color: $secondary;
    text-decoration: none;
    border-bottom: 2px solid $secondary;
    &:hover {
        color: #56269c;
        text-decoration: none;
        border-bottom: 2px solid #56269c;
    }
    &:focus,
    &.focus {
        text-decoration: none;
        box-shadow: none;
    }
    &:disabled,
    &.disabled {
        color: $btn-link-disabled-color;
        pointer-events: none;
    }
}

// Soft variants

@mixin btn-variant-soft($bg, $color) {
    background-color: $bg;
    color: $color;
    &:hover {
        background-color: fade-in($bg, 0.05);
        color: $color;
    }
}

@each $color, $value in $theme-colors {
    .btn-#{$color}-soft {
        @include btn-variant-soft(rgba($value, $btn-soft-bg-opacity), $value);
    }
}

// btn play

.btn-play {
    background-color: var(--#{$prefix}white);
    color: var(--#{$prefix}primary);
    transition: 0.2s ease;
    &:hover {
        background-color: var(--#{$prefix}primary);
        color: var(--#{$prefix}white);
        transition: 0.2s ease;
    }
}

// btn group

.nav.btn-group > .btn,
.btn-group-vertical > .btn {
    flex: 0;
}

.nav.btn-group {
    .btn-white {
        &.active {
            background-color: var(--#{$prefix}primary);
            border-color: var(--#{$prefix}primary);
            color: #fff;
        }
        &:focus {
            box-shadow: none !important;
        }
    }
}

// btn like

.btn-like {
    border: 1px solid var(--#{$prefix}gray-200);
    background-color: transparent;
    height: 32px;
    width: 32px;
    display: block;
    text-align: center;
    border-radius: 100%;
    font-size: 14px;
    line-height: 34px;
    cursor: pointer;
    transition: 0.2s ease-in;
    color: var(--#{$prefix}gray-300);
    &:hover {
        background-color: var(--#{$prefix}primary);
        color: var(--#{$prefix}white);
        border-color: var(--#{$prefix}primary);
        transition: 0.2s ease-in;
    }
}

// btn icon

.btn-icon {
    position: relative;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 0.92969rem;
    font-weight: 400;
    width: 2.5rem;
    height: 2.5rem;
    padding: 0;
}

.btn-icon.btn-xs {
    font-size: 0.75rem;
    width: 1.53125rem;
    height: 1.53125rem;
}

.btn-icon.btn-sm {
    font-size: 0.875rem;
    width: 2.1875rem;
    height: 2.1875rem;
}

.btn-icon.btn-lg {
    font-size: 1rem;
    width: 3.36875rem;
    height: 3.36875rem;
}
