/*
Theme: Coach - Online Coaching Bootstrap Theme
Product Page: https://easetemplate.com/html/coach
*/

// function.scss
@import "./bootstrap/scss/_functions";
// User Variables
@import "user-variables";
// theme variables
@import "theme/variables";
//utilities
@import "theme/utilities";
// Bootstrap
@import "./bootstrap/scss/bootstrap.scss";
// Components
@import "theme/theme";
// User
@import "user";
