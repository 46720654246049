// Img overlay

.img-overlay {
  position: relative;
  transition: 0.5s ease;
  .caption {
    transition: 0.5s ease;
    opacity: 1;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }
  &:hover .btn {
    color: var(--#{$prefix}dark);
    background-color: var(--#{$prefix}white);
    border-color: var(--#{$prefix}white);
  }
}

// bg overlay

.bg-overlay {
  background: linear-gradient(180deg, rgba(30, 24, 53, 0.4) 0%, rgba(30, 24, 53, 0.4) 90.16%);
}
