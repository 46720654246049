//
// Extended From Bootstrap
// table

.table {
    thead th {
        font-size: 13px;
        line-height: 26px;
    }
}
