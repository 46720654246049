// social btn

.social-btn {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition:
        border-color 0.25s ease-in-out,
        background-color 0.25s ease-in-out,
        color 0.25s ease-in-out;
}

// Round shape

.sb-round {
    border-radius: 50% !important;
}

// Brand colors

.sb-facebook:hover {
    background-color: #3b5998;
    color: #fff;
    border-color: #3b5998;
}

.sb-twitter:hover {
    background-color: #1da1f2;
    color: #fff;
    border-color: #1da1f2;
}

.sb-instagram:hover {
    background-color: #5851db;
    color: #fff;
    border-color: #5851db;
}

.sb-google:hover {
    background-color: #ea4335;
    color: #fff;
    border-color: #ea4335;
}

.sb-linkedin:hover {
    background-color: #0077b5;
    color: #fff;
    border-color: #0077b5;
}

.sb-pinterest:hover {
    background-color: #bd081c;
    color: #fff;
    border-color: #bd081c;
}

.sb-tumblr:hover {
    background-color: #35465c;
    color: #fff;
    border-color: #35465c;
}

.sb-behance:hover {
    background-color: #1769ff;
    color: #fff;
    border-color: #1769ff;
}

.sb-dribbble:hover {
    background-color: #ea4c89;
    color: #fff;
    border-color: #ea4c89;
}

.sb-vk:hover {
    background-color: #45668e;
    color: #fff;
    border-color: #45668e;
}

.sb-odnoklassniki:hover {
    background-color: #ed812b;
    color: #fff;
    border-color: #ed812b;
}

.sb-skype:hover {
    background-color: #00aff0;
    color: #fff;
    border-color: #00aff0;
}

.sb-hangouts:hover {
    background-color: #0f9d58;
    color: #fff;
    border-color: #0f9d58;
}

.sb-youtube:hover {
    background-color: #ff0000;
    color: #fff;
    border-color: #ff0000;
}

.sb-figma:hover {
    background-color: #a259ff;
    color: #fff;
    border-color: #a259ff;
}

.sb-github:hover {
    background-color: #4078c0;
    color: #fff;
    border-color: #4078c0;
}
