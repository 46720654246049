// Nav pills

.nav-pills.nav-pills-border {
    .nav-item {
        .nav-link {
            border: 1px solid var(--#{$prefix}gray-200);
            border-radius: 4px;
            margin-right: -1px;
            &.active {
                color: var(--#{$prefix}white);
                background-color: var(--#{$prefix}primary);
                border: 1px solid var(--#{$prefix}primary);
            }
        }
    }
}

// custom pills

.custom-pill {
    .nav-pills {
        .nav-item {
            .nav-link {
                border-bottom: 3px solid transparent;
                border-radius: 0px;
                padding-bottom: 20px;
                &.active {
                    background-color: transparent;
                    border-bottom: 3px solid var(--#{$prefix}primary);
                    padding-bottom: 20px;
                }
            }
        }
    }
    .tab-content {
        background-color: var(--#{$prefix}light-warning);
        padding: 30px;
        border-radius: 0px 0px 4px 4px;
    }
}

// nav footer links

.nav-footer-links {
    .nav {
        .nav-item {
            .nav-link {
                font-size: 12px;
                color: var(--#{$prefix}gray-900);
                padding: 2px 8px;
                &:hover {
                    color: var(--#{$prefix}primary);
                }
            }
        }
    }
}

// nav simple

.nav-simple {
    .navbar-nav {
        .nav-item {
            .nav-link {
                font-size: 16px;
                color: var(--#{$prefix}dark);
            }
        }

        .dropdown-toggle::after {
            font-family: "Feather" !important;
            content: "\e92e";
            border: 0;
            vertical-align: top;
            margin-left: 0px;
        }
    }
}
