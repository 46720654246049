// Zoom

.img-zoom {
  border-radius: 0.25rem;
  overflow: hidden;
  img {
    transition: transform 0.5s ease;
    &:hover {
      transform: scale(1.1);
    }
  }
}

.img-hover {
  box-shadow: 0 3px 6px rgba(3, 0, 71, 0.1);
  transition: 0.3s;
  &:hover {
    box-shadow:
      0 2px 4px rgba(0, 0, 20, 0.08),
      0 1px 2px rgba(0, 0, 20, 0.08);
    transform: scale(1.02);
    -webkit-transform: scale(1.02);
    -moz-transform: scale(1.02);
  }
}
