// leaflet

#mapid {
  height: 300px;
}

.leaflet-pane {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 400;
  > svg {
    position: absolute;
    left: 0;
    top: 0;
    path {
      pointer-events: none;
    }
    path.leaflet-interactive {
      pointer-events: visiblePainted;
      pointer-events: auto;
    }
  }
  > canvas {
    position: absolute;
    left: 0;
    top: 0;
  }
}

.leaflet-tile {
  position: absolute;
  left: 0;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  filter: inherit;
  visibility: hidden;
  &::selection {
    background: transparent;
  }
}

.leaflet-marker-icon {
  position: absolute;
  left: 0;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  display: block;
  pointer-events: none;
}

.leaflet-marker-shadow {
  position: absolute;
  left: 0;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  display: block;
  pointer-events: none;
}

.leaflet-tile-container {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
}

.leaflet-zoom-box {
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 800;
  border: 2px dotted #38f;
  background: rgba(255, 255, 255, 0.5);
}

.leaflet-image-layer {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
}

.leaflet-layer {
  position: absolute;
  left: 0;
  top: 0;
}

.leaflet-container {
  overflow: hidden;
  -webkit-tap-highlight-color: transparent;
  background: var(--#{$prefix}gray-200);
  outline: 0;
  font: 12px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif;
  .leaflet-overlay-pane {
    svg {
      max-width: none !important;
      max-height: none !important;
    }
  }
  .leaflet-marker-pane {
    img {
      max-width: none !important;
      max-height: none !important;
    }
  }
  .leaflet-shadow-pane {
    img {
      max-width: none !important;
      max-height: none !important;
    }
  }
  .leaflet-tile-pane {
    img {
      max-width: none !important;
      max-height: none !important;
    }
  }
  img.leaflet-image-layer {
    max-width: none !important;
    max-height: none !important;
  }
  .leaflet-tile {
    max-width: none !important;
    max-height: none !important;
  }
  a {
    -webkit-tap-highlight-color: rgba(51, 181, 229, 0.4);
    color: $primary;
  }
  a.leaflet-active {
    outline: 2px solid orange;
  }
  .leaflet-control-attribution {
    background: var(--#{$prefix}white);
    background: rgba(255, 255, 255, 0.7);
    margin: 0;
    font-size: 11px;
  }
  .leaflet-control-scale {
    font-size: 11px;
  }
  a.leaflet-popup-close-button {
    position: absolute;
    top: 3px;
    right: 5px;
    padding: 4px 4px 0 0;
    border: none;
    text-align: center;
    width: 18px;
    height: 14px;
    font: 16px/14px Tahoma, Verdana, sans-serif;
    color: var(--#{$prefix}gray-900);
    text-decoration: none;
    font-weight: bold;
    background: transparent;
    &:hover {
      color: var(--#{$prefix}dark);
    }
  }
}

.leaflet-safari {
  .leaflet-tile {
    image-rendering: -webkit-optimize-contrast;
  }
  .leaflet-tile-container {
    width: 1600px;
    height: 1600px;
    -webkit-transform-origin: 0 0;
  }
}

.leaflet-container.leaflet-touch-zoom {
  -ms-touch-action: pan-x pan-y;
  touch-action: pan-x pan-y;
}

.leaflet-container.leaflet-touch-drag {
  -ms-touch-action: pinch-zoom;
  touch-action: none;
  touch-action: pinch-zoom;
}

.leaflet-container.leaflet-touch-drag.leaflet-touch-zoom {
  -ms-touch-action: none;
  touch-action: none;
}

.leaflet-tile-loaded {
  visibility: inherit;
}

.leaflet-overlay-pane {
  svg {
    -moz-user-select: none;
  }
  z-index: 400;
}

.leaflet-tile-pane {
  z-index: 200;
}

.leaflet-shadow-pane {
  z-index: 500;
}

.leaflet-marker-pane {
  z-index: 600;
}

.leaflet-tooltip-pane {
  z-index: 650;
}

.leaflet-popup-pane {
  z-index: 700;
  cursor: auto;
}

.leaflet-map-pane {
  canvas {
    z-index: 100;
  }
  svg {
    z-index: 200;
  }
}

.leaflet-vml-shape {
  width: 1px;
  height: 1px;
}

.lvml {
  behavior: url(#default#VML);
  display: inline-block;
  position: absolute;
}

.leaflet-control {
  position: relative;
  z-index: 800;
  pointer-events: visiblePainted;
  pointer-events: auto;
  float: left;
  clear: both;
  cursor: auto;
}

.leaflet-top {
  position: absolute;
  z-index: 1000;
  pointer-events: none;
  top: 0;
  .leaflet-control {
    margin-top: 10px;
  }
}

.leaflet-bottom {
  position: absolute;
  z-index: 1000;
  pointer-events: none;
  bottom: 0;
  .leaflet-control {
    margin-bottom: 10px;
  }
  .leaflet-control-scale {
    margin-bottom: 5px;
  }
}

.leaflet-right {
  right: 0;
  .leaflet-control {
    float: right;
    margin-right: 10px;
  }
}

.leaflet-left {
  left: 0;
  .leaflet-control {
    margin-left: 10px;
  }
  .leaflet-control-scale {
    margin-left: 5px;
  }
}

.leaflet-fade-anim {
  .leaflet-tile {
    will-change: opacity;
  }
  .leaflet-popup {
    opacity: 0;
    -webkit-transition: opacity 0.2s linear;
    -moz-transition: opacity 0.2s linear;
    transition: opacity 0.2s linear;
  }
  .leaflet-map-pane {
    .leaflet-popup {
      opacity: 1;
    }
  }
}

.leaflet-zoom-animated {
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
}

.leaflet-zoom-anim {
  .leaflet-zoom-animated {
    will-change: transform;
    -webkit-transition: -webkit-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
    -moz-transition: -moz-transform 0.25s cubic-bezier(0, 0, 0.25, 1);
    transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1);
  }
  .leaflet-tile {
    -webkit-transition: none;
    -moz-transition: none;
    transition: none;
  }
  .leaflet-zoom-hide {
    visibility: hidden;
  }
}

.leaflet-pan-anim {
  .leaflet-tile {
    -webkit-transition: none;
    -moz-transition: none;
    transition: none;
  }
}

.leaflet-interactive {
  cursor: pointer;
}

.leaflet-grab {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
}

.leaflet-crosshair {
  cursor: crosshair;
  .leaflet-interactive {
    cursor: crosshair;
  }
}

.leaflet-dragging {
  .leaflet-grab {
    cursor: move;
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
    cursor: grabbing;
    .leaflet-interactive {
      cursor: move;
      cursor: -webkit-grabbing;
      cursor: -moz-grabbing;
      cursor: grabbing;
    }
  }
  .leaflet-marker-draggable {
    cursor: move;
    cursor: -webkit-grabbing;
    cursor: -moz-grabbing;
    cursor: grabbing;
  }
}

.leaflet-marker-icon.leaflet-interactive {
  pointer-events: visiblePainted;
  pointer-events: auto;
}

.leaflet-image-layer.leaflet-interactive {
  pointer-events: visiblePainted;
  pointer-events: auto;
}

svg.leaflet-image-layer.leaflet-interactive {
  path {
    pointer-events: visiblePainted;
    pointer-events: auto;
  }
}

.leaflet-bar {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.65);
  border-radius: 4px;
  a {
    background-color: var(--#{$prefix}white);
    border-bottom: 1px solid var(--#{$prefix}gray-200);
    width: 26px;
    height: 26px;
    line-height: 26px;
    display: block;
    text-align: center;
    text-decoration: none;
    color: var(--#{$prefix}dark);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    display: block;
    &:hover {
      background-color: var(--#{$prefix}white);
      border-bottom: 1px solid var(--#{$prefix}gray-200);
      width: 26px;
      height: 26px;
      line-height: 26px;
      display: block;
      text-align: center;
      text-decoration: none;
      color: var(--#{$prefix}dark);
      background-color: var(--#{$prefix}gray-100);
    }
    &:first-child {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
    &:last-child {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      border-bottom: none;
    }
  }
  a.leaflet-disabled {
    cursor: default;
    background-color: var(--#{$prefix}gray-100);
    color: var(--#{$prefix}gray-400);
  }
}

.leaflet-control-layers-toggle {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  display: block;
  background-image: url(images/layers.png);
  width: 36px;
  height: 36px;
}

.leaflet-touch {
  .leaflet-bar {
    a {
      width: 30px;
      height: 30px;
      line-height: 30px;
      &:first-child {
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
      }
      &:last-child {
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
      }
    }
    box-shadow: none;
    border: 2px solid rgba(0, 0, 0, 0.2);
    background-clip: padding-box;
  }
  .leaflet-control-zoom-in {
    font-size: 22px;
  }
  .leaflet-control-zoom-out {
    font-size: 22px;
  }
  .leaflet-control-layers-toggle {
    width: 44px;
    height: 44px;
  }
  .leaflet-control-attribution {
    box-shadow: none;
  }
  .leaflet-control-layers {
    box-shadow: none;
    border: 2px solid rgba(0, 0, 0, 0.2);
    background-clip: padding-box;
  }
}

.leaflet-control-zoom-in {
  font: bold 18px "Lucida Console", Monaco, monospace;
  text-indent: 1px;
}

.leaflet-control-zoom-out {
  font: bold 18px "Lucida Console", Monaco, monospace;
  text-indent: 1px;
}

.leaflet-control-layers {
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4);
  background: var(--#{$prefix}white);
  border-radius: 5px;
  .leaflet-control-layers-list {
    display: none;
  }
  label {
    display: block;
  }
}

.leaflet-retina {
  .leaflet-control-layers-toggle {
    background-image: url(images/layers-2x.png);
    background-size: 26px 26px;
  }
}

.leaflet-control-layers-expanded {
  .leaflet-control-layers-toggle {
    display: none;
  }
  .leaflet-control-layers-list {
    display: block;
    position: relative;
  }
  padding: 6px 10px 6px 6px;
  color: var(--#{$prefix}dark);
  background: var(--#{$prefix}white);
}

.leaflet-control-layers-scrollbar {
  overflow-y: scroll;
  overflow-x: hidden;
  padding-right: 5px;
}

.leaflet-control-layers-selector {
  margin-top: 2px;
  position: relative;
  top: 1px;
}

.leaflet-control-layers-separator {
  height: 0;
  border-top: 1px solid var(--#{$prefix}gray-200);
  margin: 5px -10px 5px -6px;
}

.leaflet-default-icon-path {
  background-image: url(images/marker-icon.png);
}

.leaflet-control-attribution {
  padding: 0 5px;
  color: var(--#{$prefix}dark);
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

.leaflet-control-scale-line {
  padding: 0 5px;
  color: var(--#{$prefix}dark);
  border: 2px solid var(--#{$prefix}gray-200);
  border-top: none;
  line-height: 1.1;
  padding: 2px 5px 1px;
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  background: var(--#{$prefix}white);
  background: rgba(255, 255, 255, 0.5);
  &:not(:first-child) {
    border-top: 2px solid var(--#{$prefix}gray-200);
    border-bottom: none;
    margin-top: -2px;
    &:not(:last-child) {
      border-bottom: 2px solid var(--#{$prefix}gray-200);
    }
  }
}

.leaflet-popup {
  position: absolute;
  text-align: center;
  margin-bottom: 20px;
  width: 240px;
}

.leaflet-popup-content-wrapper {
  padding: 0px;
  text-align: left;
  border-radius: 12px;
  background: white;
  color: var(--#{$prefix}dark);
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
}

.leaflet-popup-content {
  margin: 0px;
  line-height: 1.4;
  p {
    margin: 18px 0;
  }
}

.leaflet-popup-tip-container {
  width: 40px;
  height: 20px;
  position: absolute;
  left: 50%;
  margin-left: -20px;
  overflow: hidden;
  pointer-events: none;
  bottom: -20px;
}

.leaflet-popup-tip {
  width: 17px;
  height: 17px;
  padding: 1px;
  margin: -10px auto 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  background: white;
  color: var(--#{$prefix}dark);
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0.4);
}

.leaflet-popup-scrolled {
  overflow: auto;
  border-bottom: 1px solid var(--#{$prefix}gray-200);
  border-top: 1px solid var(--#{$prefix}gray-200);
}

.leaflet-oldie {
  .leaflet-popup-content-wrapper {
    zoom: 1;
    border: 1px solid #999;
  }
  .leaflet-popup-tip {
    width: 24px;
    margin: 0 auto;
    border: 1px solid #999;
  }
  .leaflet-popup-tip-container {
    margin-top: -1px;
  }
  .leaflet-control-zoom {
    border: 1px solid #999;
  }
  .leaflet-control-layers {
    border: 1px solid #999;
  }
}

.leaflet-div-icon {
  background: var(--#{$prefix}white);
  border: 1px solid #666;
}

.leaflet-tooltip {
  position: absolute;
  padding: 6px;
  background-color: var(--#{$prefix}white);
  border: 1px solid var(--#{$prefix}white);
  border-radius: 3px;
  color: var(--#{$prefix}dark);
  white-space: nowrap;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
}

.leaflet-tooltip.leaflet-clickable {
  cursor: pointer;
  pointer-events: auto;
}

.leaflet-tooltip-top {
  &:before {
    position: absolute;
    pointer-events: none;
    border: 6px solid transparent;
    background: transparent;
    content: "";
    left: 50%;
    margin-left: -6px;
    bottom: 0;
    margin-bottom: -12px;
    border-top-color: var(--#{$prefix}white);
  }
  margin-top: -6px;
}

.leaflet-tooltip-bottom {
  &:before {
    position: absolute;
    pointer-events: none;
    border: 6px solid transparent;
    background: transparent;
    content: "";
    left: 50%;
    margin-left: -6px;
    top: 0;
    margin-top: -12px;
    margin-left: -6px;
    border-bottom-color: var(--#{$prefix}white);
  }
  margin-top: 6px;
}

.leaflet-tooltip-left {
  &:before {
    position: absolute;
    pointer-events: none;
    border: 6px solid transparent;
    background: transparent;
    content: "";
    top: 50%;
    margin-top: -6px;
    right: 0;
    margin-right: -12px;
    border-left-color: var(--#{$prefix}white);
  }
  margin-left: -6px;
}

.leaflet-tooltip-right {
  &:before {
    position: absolute;
    pointer-events: none;
    border: 6px solid transparent;
    background: transparent;
    content: "";
    top: 50%;
    margin-top: -6px;
    left: 0;
    margin-left: -12px;
    border-right-color: var(--#{$prefix}white);
  }
  margin-left: 6px;
}
