// Avatar size start

.avatar-xs {
  width: $avatar-size-xs;
  height: $avatar-size-xs;
  font-size: $avatar-size-xs * 3;
}

.avatar-sm {
  width: $avatar-size-sm;
  height: $avatar-size-sm;
  font-size: $avatar-size-sm * 3;
}
.avatar-md {
  width: $avatar-size-md;
  height: $avatar-size-md;
  font-size: $avatar-size-md * 3;
}

.avatar-lg {
  width: $avatar-size-lg;
  height: $avatar-size-lg;
  font-size: $avatar-size-lg * 3;
}

.avatar-xl {
  width: $avatar-size-xl;
  height: $avatar-size-xl;
  font-size: $avatar-size-xl * 3;
}

.avatar-xxl {
  width: $avatar-size-xxl;
  height: $avatar-size-xxl;
  font-size: $avatar-size-xxl * 3;
}

/* avatar-size end */
